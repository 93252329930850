import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AppBar from './AppBar';
import Footer from './Footer';
import ArticleCard from './ArticleCard';

import homeStyles from './Home.css';

const Pickup = ({data}) => {
  if(process.browser)document.title='最近よく読まれている記事 - Gigazine App';
  return (
    <React.Fragment>
      <AppBar />
      <div className={homeStyles.home}>
        {data.map((obj, index)=>(
          <ArticleCard data={obj} key={index} />
        ))}
      </div>
      <Footer />
    </React.Fragment>
  );
};

Pickup.propTypes = {
  data: PropTypes.array
};

export default connect(
  (state, ownProps)=>{
    return {
      data: state.ranking,
      ...ownProps
    };
  }, null)(Pickup);