import { OneSignal } from '../actionTypes';

export const set = (key, value) => {
  return {
    type: OneSignal.set,
    key,
    value
  };
};

export const get = (key) => {
  return {
    type: OneSignal.get,
    key
  };
};