export const homestream = (page, category)=>{
  if(!page && !category) {
    return '/api/homestream';
  }
  const pagestr = page ? `&page=${page}` : '';
  const categorystr = category? `&category=${category}` : '';
  return `/api/homestream?${(pagestr+categorystr).slice(1)}`;
};

export const article = url=>`/api/article/${url}`;

export const articles = '/api/articles';

export const ranking = ()=>'/api/ranking';

export const issue = '/api/issue';