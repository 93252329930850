import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { article } from '../actions';
import { parse } from '../lib/parse';
import ArticleCard from './ArticleCard';
import Header from './AppBar';
import Footer from './Footer';

import ArticleStyles from './Article.css';

export const Article = (props) =>{
  const loading = !props.articleData;
  if(loading){
    props.loadArticle(props.url);
  } else {
    if(process.browser)document.title = `${props.articleData.title} - GIGAZINE`;
  }
  const related = loading?[]:props.articleData.related_contents || [];
  const relatedArticle = loading?[]
    :props.articleData.related || [];
  const textDataList = loading?null:
    parse(props.articleData.text, ArticleStyles, props.test);
  const articleMain = loading?'loading...':
    [
      textDataList[0],
      <h1 className={ArticleStyles.title} key='title'>{props.articleData.title}</h1>,
      ...textDataList.slice(1)
    ];

  return (
    <React.Fragment>
      <Header />
      <React.Fragment>
        <main className={ArticleStyles.root}>
          {articleMain}
          {loading?null:<div className={ArticleStyles.kanren}>
            <h3>・関連記事</h3>
            {/新作アニメ一覧/.test(props.articleData.title)?relatedArticle.sort((a, b)=>(b.url.substr(0,8)-a.url.substr(0,8))).map((data, index)=>(
              <p key={index}><b><Link to={data.url}>{data.title}</Link></b></p>
            )):relatedArticle.map((data,index)=>(
              <p key={index}><b><Link to={data.url}>{data.title}</Link></b></p>
            ))}
            <h3>・関連コンテンツ</h3>
            {related.map((data, index)=>(
              <ArticleCard data={data} key={index} />
            ))}
          </div>}
        </main>
      </React.Fragment>
      <Footer />
    </React.Fragment>
  );
};

Article.propTypes = {
  articleData: PropTypes.object,
  url: PropTypes.string.isRequired,
  loadArticle: PropTypes.func,
  test: PropTypes.bool
};

const mapStateToProps = (state, ownProps)=>{
  const url = ownProps.match.params.url;
  return {
    allArticles: state.articles,
    articleData: state.articles[url],
    url:url,
    ...ownProps
  };
};
const mapDispatchToProps = (dispatch)=>{
  return {
    loadArticle: bindActionCreators(article.request, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Article);