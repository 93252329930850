import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { issue } from '../actions';
import Header from './AppBar';
import Footer from './Footer';

import feedbackStyle from './Issues.css';

export const Issueform = (props) => {
  const data = props.issueData;
  const edit = props.edit;
  const handleChange = (event) =>{
    const target = event.target;
    edit({
      [target.name]:target.value
    });
  };
  return (
    <React.Fragment>
      <Header />
      <div className={feedbackStyle.root}>
        <form>
          <label>タイトル<br />
            <input type="text" name="title" value={data.title} onChange={handleChange} disabled={data.sending}/>
          </label>
          <label>詳細<br />
            <textarea name="description" value={data.description} onChange={handleChange} disabled={data.sending}/>
          </label>
          <button className={feedbackStyle.send} onClick={()=>{
            props.send(data.title, data.description);
          }} disabled={data.sending}>送信</button>
        </form>
        {data.sending?<p>送信中...</p>:data.id?<p>送信しました。報告ありがとうございます。</p>:data.errormessage?<p>送信エラー</p>:null}
        <p>たくさんの報告ありがとうございます。大変助かっています！</p>
      </div>
      <Footer />
    </React.Fragment>
  );
};

Issueform.propTypes = {
  issueData: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  send: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps)=>{
  return {
    issueData: state.issue,
    ...ownProps
  };
};
const mapDispatchToProps = (dispatch)=>{
  return {
    edit: bindActionCreators(issue.edit, dispatch),
    send: bindActionCreators(issue.create, dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Issueform);