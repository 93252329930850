import { Issues } from '../actionTypes';

export const create = (title, description = '') => {
  return {
    type: Issues.create,
    title,
    description
  };
};

export const success = (id) => {
  return {
    type: Issues.success,
    id
  };
};

export const error = (message) => {
  return {
    type: Issues.error,
    message
  };
};

export const edit = (data)=>{
  return {
    type: Issues.edit,
    data
  };
};