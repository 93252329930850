exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Footer__root__31ZVJ {\n  width: 100%;\n  height: 60px;\n  z-index: 100;\n  background-color: rgb(243, 243, 239);\n  display: flex;\n  position: fixed;\n  bottom: 0;\n}\n.Footer__item__13gQU {\n  margin: auto;\n  flex-grow: 1;\n  cursor: pointer;\n  display: flex;\n  color: gray;\n  flex-direction: column;\n  text-align: center;\n  text-decoration: none;\n}\n.Footer__item__13gQU > * {\n  margin: auto;\n}\n.Footer__isActive__317Gt {\n  color: black;\n}", ""]);

// exports
exports.locals = {
	"root": "Footer__root__31ZVJ",
	"item": "Footer__item__13gQU",
	"isActive": "Footer__isActive__317Gt"
};