import { LikeList } from '../actionTypes';
import store from 'store';

const loadFromStorage = store.get('__likeList__') || {
  head: null,
  list: {}
};

export default(state = loadFromStorage, action) => {
  switch(action.type){
  case LikeList.add:{
    if(state.list[action.url])return state;
    const toRet = {
      head: action.url,
      list: {
        ...state.list,
        [action.url]: {
          title: action.title,
          next: state.head,
          prev: null
        },
        [state.head]:{
          ...state.list[state.head],
          prev: action.url
        }
      }
    };
    store.set('__likeList__', toRet);
    return toRet;
  }
  case LikeList.remove:{
    if(!state.list[action.url]){
      return state;
    }
    const nextHead = !state.head
      ? null
      : state.head === action.url
        ? state.list[state.head].next
        : state.head;
    const nextlist = {...state.list};
    const nextNode = state.list[action.url].next;
    const prevNode = state.list[action.url].prev;
    if(nextNode){
      nextlist[nextNode] = {
        ...state.list[nextNode],
        prev: prevNode
      };
    }
    if(prevNode){
      nextlist[prevNode] = {
        ...state.list[prevNode],
        next: nextNode
      };
    }
    delete nextlist[action.url];
    const toRet = {
      head: nextHead,
      list: nextlist
    };
    store.set('__likeList__', toRet);
    return toRet;
  }
  case LikeList.read:{
    const nextHead = !state.head
      ? null
      : state.head === action.url
        ? state.list[state.head].next
        : state.head;
    const toRet = {
      head: nextHead,
      list: state.list
    };
    store.set('__likeList__', toRet);
    return toRet;
  }
  default:
    return state;
  }
};