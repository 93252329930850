import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { homestream } from '../actions/';
import style from './Header.css';
import Logo from './logo.svg';

const Header = (props)=>{
  const isHome = props.router.location.pathname === '/';
  const load = props.loadHomeScreen;
  return (
    <div className={style.root}>
      <Link to="/" title="トップページへ" className={style.logo}>
        <Logo width={200} height={58} 
          onMouseOver={isHome?null:load}
          onClick={isHome?load:null}
        />
      </Link>
      <Link to="/feedback" className={style.feedback}>バグ報告</Link>
    </div>
  );
};

Header.propTypes = {
  router: PropTypes.object,
  loadHomeScreen: PropTypes.func
};

const mapStateToProps = (state, ownProps)=>{
  return {
    router: state.router,
    ...ownProps
  };
};
const mapDispatchToProps = (dispatch)=>{
  return {
    loadHomeScreen: bindActionCreators(homestream.reload, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);