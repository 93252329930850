import _hogehoge from './hogehoge.action';
import * as _homestream from './homestream.action';
import * as _article from './article.action';
import * as _pagenumber from './pagenumber.action';
import * as _issue from './issue.action';
import * as _ranking from './ranking.action';
import * as _likelist from './likeList.action';
import * as _onesignal from './onesignal.action';

export const hogehoge = _hogehoge;
export const homestream = _homestream;
export const article = _article;
export const pagenumber = _pagenumber;
export const issue = _issue;
export const ranking = _ranking;
export const likelist = _likelist;
export const onesignal = _onesignal;

export default {
  hogehoge,
  homestream,
  article
};