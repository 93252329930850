import { Issues } from '../actionTypes';

export default (state = {
  title: '',
  description: '',
  errormessage: '',
  sending: false,
  id:null
}, action) =>{
  switch (action.type){
  case Issues.edit:
    return {...state, ...action.data };
  case Issues.success:
    return {
      title: '',
      description: '',
      errormessage: '',
      id: action.id,
      sending: false
    };
  case Issues.error:
    return {
      ...state,
      errormessage: action.message,
      id:null,
      sending: false
    };
  case Issues.create:
    return {...state, sending: true};
  default:
    return state;
  }
};