export const HOGEHOGE = {
  request: 'REQUEST_FETCH_HOGEHOGE',
  fetched: 'FETCHED_HOGEHOGE',
  error: 'ERROR_HOGEHOGE'
};

export const HOMESTREAM = {
  request: 'REQUEST_FETCH_HOMESTREAM',
  fetched: 'FETCHED_HOMESTREAM',
  error: 'ERROR_ON_FETCH_HOMESTREAM',
  reload: 'RELOAD_FETCH_HOMESTREAM'
};

export const PageNumber = {
  inc: 'HOMESTREAM_PAGENUMBER_INCREMENT',
  reset: 'HOMESTREAM_PAGENUMBER_RESET',
  next: 'NEXTPAGE_REQUEST'
};

export const ARTICLE = {
  request: 'REQUEST_FETCH_ARTICLE',
  fetched: 'FETCHED_ARTICLE',
  error: 'ERROR_ON_FETCH_ARTICLE',
  save: 'SAVE_ARTICLE_TO_LOCAL',
  load: 'LOAD_ARTICLES_FROM_LOCAL'
};

export const Router = {
  locationChange: '@@router/LOCATION_CHANGE'
};

export const Issues = {
  create: 'CREATE_ISSUE',
  success: 'ISSUE_SUCCESSFLY_CREATED',
  error: 'ERROR_ON_CREATING_ISSUE',
  edit: 'ISSUE_EDITTING'
};

export const Ranking = {
  request: 'REQUEST_FETCH_RANKING',
  fetched: 'FETCHED_RANKING_DATA'
};

export const LikeList = {
  add: 'LIKELIST_ADD_CONTENT',
  remove: 'LIKELIST_REMOVE_CONTENT',
  load: 'LIKELIST_LOAD_FROM_STORAGE',
  read: 'LIKELIST_MARKED_AS_READ'
};

export const OneSignal = {
  set: 'ONESIGNAL_SET_VALUE',
  get: 'ONESIGNAL_GET_VALUE'
};