import { HOMESTREAM } from '../actionTypes';

export default (state = [], action)=>{
  let nextState = [];
  switch (action.type) {
  case HOMESTREAM.fetched:
    nextState = action.page === 0?
      [...action.json]:
      [...state];
    state.forEach(newelem => {
      nextState.find(elem=>elem.url===newelem.url)? null : nextState.push(newelem);
    });
    action.json.forEach(newelem => {
      nextState.find(elem=>elem.url===newelem.url)? null : nextState.push(newelem);
    });
    return action.reset?action.json:nextState;
  default:
    return state;
  }
};