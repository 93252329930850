import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './components/Home';
import Article from './components/Article';
import Pickup from './components/PickUp';
import Issue from './components/Issues';
import List from './components/List';
import Setting from './components/Setting';
import './root.css';

const App = ()=>(
  <Switch>
    <Route path='/news/:url' component={Article} />
    <Route path='/feedback' component={Issue} />
    <Route path='/ranking' component={Pickup} />
    <Route path='/list' component={List} />
    <Route path='/setting' component={Setting} />
    <Route path='/' component={Home}/>
  </Switch>
);


export default App;