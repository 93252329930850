import { Ranking } from '../actionTypes';

export const request = () => {
  return {
    type: Ranking.request
  };
};

export const fetched = (data) => {
  return {
    type: Ranking.fetched,
    data
  };
};