import { take, call, put } from 'redux-saga/effects';
import fetch from 'isomorphic-fetch';

import { Issues } from '../actionTypes';
import { issue as act } from '../actions';
import { issue } from '../lib/api';

export default function* (){
  while(true){
    const action = yield take(Issues.create);
    const res = yield call(fetch, issue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: action.title,
        description: action.description
      })
    });
    const code = yield call(res=>res.json(), res);
    if(code.status === 'success'){
      yield put(act.success(code.id));
    } else {
      yield put(act.error(code.message));
    }
  }
}