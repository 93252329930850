import { HOGEHOGE } from '../actionTypes';

export default (state = {}, action)=>{
  switch (action.type) {
  case HOGEHOGE.fetched:
    return action.json.data;
    // return Object.assign({},state, {hogehoge:action.json.data});
  default:
    return state;
  }
};