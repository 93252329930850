import { take, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { Router as type } from '../actionTypes';
import { homestream, ranking } from '../actions';

export default function* (){
  let prev = '';
  while(true){
    const { payload } = yield take(type.locationChange);
    yield delay(1);
    if(payload.action!=='POP')window.scrollTo(0,0);
    if(prev !== payload.location.pathname && window.gtag){
      window.gtag('config', 'UA-134978-13', {'page_path': payload.location.pathname});
      prev = payload.location.pathname;
    }
    if(payload.location.pathname === '/'){
      const reset = payload.action === 'PUSH' ? true : false;
      yield put(homestream.request2({reset}));
    }
    if(payload.location.pathname === '/ranking'){
      yield put(ranking.request());
    }
  }
}