exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Header__root__25D-U {\n  width: 100%;\n  height: 60px;\n  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 2px 9px 1px rgba(0, 0, 0, 0.12), 0 4px 2px -2px rgba(0, 0, 0, 0.2);\n  z-index: 100;\n  background-color: white;\n  display: flex;\n}\n.Header__logo__24w6Q {\n  cursor: pointer;\n  margin-left: 3px;\n  margin-top: 1px;\n  flex: 1;\n}\n\n.Header__feedback__21fRc {\n  font-size: 20px;\n  margin-right: 12px;\n  margin-top: 14px;\n  text-decoration: none;\n  color: #3f51b5;\n}", ""]);

// exports
exports.locals = {
	"root": "Header__root__25D-U",
	"logo": "Header__logo__24w6Q",
	"feedback": "Header__feedback__21fRc"
};