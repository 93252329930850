import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';
import { faHeart as regHeart } from '@fortawesome/pro-regular-svg-icons';

import cardStyle from './ArticleCard.css';
import { article, likelist } from '../actions';

const MediaCard = (props)=>{
  const { data, like, unlike, lldata } = props;

  const liked = lldata.list[data.url] != undefined;
  const icon = liked ? faHeart : regHeart;
  const onClick = liked 
    ?()=>{
      unlike({
        url: data.url
      });
    }
    :()=>{
      props.loadArticle(data.url);
      like({
        url: data.url,
        title: data.title
      });
    };
  return (
    <article className={cardStyle.card} onMouseOver={()=>{props.loadArticle(data.url);}}>
      <Link to={'/news/'+data.url}>
        <img
          className={cardStyle.media}
          src={data.img}
          title={data.title}
        />
      </Link>
      <div className={cardStyle.footer}>
        <Link className={cardStyle.title} to={'/news/'+data.url}>
          <h3>
            {data.title}
          </h3>
        </Link>
        <FontAwesomeIcon
          icon={icon}
          size="2x"
          className={cardStyle.like}
          onClick={onClick}/>
      </div>
    </article>
  );
};

MediaCard.propTypes = {
  data: PropTypes.object.isRequired,
  loadArticle: PropTypes.func,
  like: PropTypes.func,
  unlike: PropTypes.func,
  lldata: PropTypes.object
};

const mapStateToProps = (state, ownProps)=>{
  return {
    lldata: state.likelist,
    ...ownProps
  };
};
const mapDispatchToProps = (dispatch)=>{
  return {
    loadArticle: bindActionCreators(article.request, dispatch),
    like: bindActionCreators(likelist.push, dispatch),
    unlike: bindActionCreators(likelist.pop, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaCard);