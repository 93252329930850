import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import createHistory from 'history/createBrowserHistory';

import * as homestreamAction from './actions/homestream.action';
import * as loadNext from './actions/pagenumber.action';
import { onesignal as onesignalact } from './actions';
import { ranking, likelist } from './actions';
import configureStore from './store';
import App from './App';

const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const history = createHistory();
const store = configureStore(history, preloadedState);


const wrap = (_App) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <_App />
    </ConnectedRouter>
  </Provider>
);

const render = (_App) =>{
  ReactDOM.render(
    wrap(_App),
    document.getElementById('root')
  );
};

const hydrate = (_App) =>{
  ReactDOM.hydrate(
    wrap(_App),
    document.getElementById('root')
  );
};

if(module.hot) {
  module.hot.accept('./App',()=>{
    const NextApp = require('./App').default;
    render(NextApp);
  });
}

hydrate(App);

const addScrollListener = ()=>{
  window.addEventListener('scroll', ()=>{
    const currentBot = window.pageYOffset + window.innerHeight;
    switch (document.location.pathname) {
    case '/':
      if(document.body.scrollHeight - currentBot < 2000)store.dispatch(loadNext.request());
      break;
    case '/list/stream':{
      const hrList = document.getElementsByTagName('hr');
      for(const hr of hrList){
        if(hr.getBoundingClientRect().y<0){
          store.dispatch(likelist.pop({url:hr.id}));
          window.scrollTo(0,59);
        }
        if(document.body.scrollHeight - currentBot < 1){
          store.dispatch(likelist.pop({
            url:hr.id
          }));
          window.history.back();
        }
      }
      break;
    }
    default:
      break;
    }
  });
};

const onesignalInit = ()=>{
  const onesignal = window.OneSignal || [];
  onesignal.push(['init', {
    appId: '18dd0cae-a763-45d2-960b-e757a5b1763c',
    autoRegister: false,
    welcomeNotification: {
      disable: true
    },
    // allowLocalhostAsSecureOrigin: true
  }]);
  onesignal.push(()=>{
    window.OneSignal.isPushNotificationsEnabled().then(isEnabled => {
      store.dispatch(onesignalact.set('subscribed', isEnabled));
    });
    window.OneSignal.on('subscriptionChange', (state)=>{
      store.dispatch(onesignalact.set('subscribed', state));
    });
  });
  onesignal.push(()=>{
    store.dispatch(onesignalact.set('windowobj', onesignal));
  });
};

const initialize = ()=>{
  store.dispatch(homestreamAction.reload());
  store.dispatch(ranking.request());
  if(process.browser){
    addScrollListener();
    onesignalInit();
  }
};

initialize();