import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltLeft, faFire, faCog, faHeart } from '@fortawesome/pro-solid-svg-icons';

import style from './Footer.css';

const partsList = [
  [faArrowAltLeft, '戻る', '/'],
  [faFire, '急上昇', '/ranking'],
  [faHeart, '後で読む', '/list'],
  [faCog, '設定', '/setting']
];

const Parts = (props)=>{
  const icon = props.icon;
  const text = props.text;
  const className = props.className;
  const onClick = props.onClick;
  const isActive = props.isActive? style.isActive : '';
  return (
    <div className={`${className} ${isActive}`} onClick={onClick}>
      <FontAwesomeIcon icon={icon} size="2x"/>
      <span style={props.isActive?{}:{color:'#666'}}>{text}</span>
    </div>
  );
};

Parts.propTypes = {
  icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool
};

const Footer = (props)=> {
  const goBack = props.goBack;
  const path = props.path;
  const activeId = path.slice(0, 8)==='/ranking'
    ? 1
    : path.slice(0, 8) === '/setting'
      ? 3
      : path.slice(0, 5) === '/list'
        ? 2 : 0;
  return (
    <nav className={style.root}>
      {partsList.map((item, index)=>(
        index===0?
          <Parts key={index} 
            icon={item[0]} 
            text={item[1]} 
            className={style.item}
            onClick={goBack} 
            isActive={
              (index === activeId)
                ?true:false 
            }/>
          : <Link to={item[2]} key={index} className={style.item}>
            <Parts
              icon={item[0]} 
              text={item[1]} 
              className={style.item}
              isActive={
                (index === activeId)
                  ?true:false 
              }/>
          </Link>
      ))}
    </nav>
  );
};

Footer.propTypes = {
  goBack: PropTypes.func,
  router: PropTypes.object,
  path: PropTypes.string
};

export default connect((state, ownProps)=>({
  router: state.router,
  path: state.router.location.pathname,
  ...ownProps
}), {goBack})(Footer);