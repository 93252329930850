import { put, take, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { PageNumber as type } from '../actionTypes';
import { homestream as act, pagenumber as pageAct} from '../actions';

export default function* (){
  while(true){
    yield take(type.next);
    const location = yield select(state=>state.router.location);
    if(location.pathname !== '/' )continue;
    const page = yield select(state=>state.pagenumber);
    yield put(act.request(page+1));
    yield put(pageAct.inc());
    yield delay(1000);
  }
}