exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Setting__root__3nYC5 {\n  max-width: 740px;\n  margin: auto;\n  padding-left: 20px;\n  padding-right: 20px;\n}", ""]);

// exports
exports.locals = {
	"root": "Setting__root__3nYC5"
};