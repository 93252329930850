exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".List__root__WXE54 {\n  max-width: 740px;\n  margin: auto auto 80px auto;\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n.List__root__WXE54 a {\n  text-decoration: none;\n}", ""]);

// exports
exports.locals = {
	"root": "List__root__WXE54"
};