import { ARTICLE } from '../actionTypes';

export const request = (url, force = false)=>{
  return {
    type:ARTICLE.request,
    url,
    force
  };
};

export const fetched = (url, data)=>{
  return {
    type:ARTICLE.fetched,
    url,
    json: data
  };
};

export const error = (err = null)=>{
  return {
    type:ARTICLE.error,
    err
  };
};

export const save = (url, data)=>{
  return {
    type:ARTICLE.save,
    url,
    data
  };
};

export const load = (url, data)=>{
  return{
    type:ARTICLE.load,
    url,
    data
  };
};

export default {
  request,
  fetched,
  error
};