import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import hogehoge from './hogehoge.reducer';
import homestream from './homestream.reducer';
import articles from './articles.reducer';
import pagenumber from './pagenumber.reducer';
import issue from './issue.reducer';
import ranking from './ranking.reducer';
import likelist from './likeList.reducer';
import onesignal from './onesignal.reducer';

export default combineReducers({
  hogehoge,
  homestream,
  routerReducer,
  articles,
  pagenumber,
  issue,
  ranking,
  likelist,
  onesignal
});