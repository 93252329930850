import { PageNumber } from '../actionTypes';

export const inc = ()=>{
  return {
    type:PageNumber.inc
  };
};

export const reset = ()=>{
  return {
    type:PageNumber.reset
  };
};

export const request = ()=>{
  return {
    type:PageNumber.next
  };
};