import { spawn } from 'redux-saga/effects';

import fetchHomestream from './fetch-homestream.saga';
import fetchArticle from './fetch-article.saga';
import scrollTop from './scrollTop.saga';
import saveArticle from './save-article.saga';
import loadNext from './loadNextPage.saga';
import reloadHomestream from './reloadHomestream.saga';
import createIssue from './createIssue.saga';
import fetchRanking from './fetch-ranking.saga';

export default function* (){
  yield spawn(fetchHomestream);
  yield spawn(fetchArticle);
  yield spawn(scrollTop);
  yield spawn(saveArticle);
  yield spawn(loadNext);
  yield spawn(reloadHomestream);
  yield spawn(createIssue);
  yield spawn(fetchRanking);
}