import { put, take, call } from 'redux-saga/effects';
import fetch from 'isomorphic-fetch';
import store from 'store';

import { articles, ranking as endpoint } from '../lib/api';
import { Ranking as type } from '../actionTypes';
import { ranking as act } from '../actions';

export default function* (){
  while(true){
    yield take(type.request);
    const res = yield call(fetch, endpoint());
    if(res.status === 200){
      const json = yield call(res=>res.json(), res);
      yield put(act.fetched(json));
      const urls = [];
      for (const data of json) {
        if(!store.get(data.url)){
          urls.push(data.url);
        }
      }
      if(urls.length>0){
        const dataStream = yield call(fetch, articles, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(urls)
        });
        const prefetchData = yield call(res=>res.json(), dataStream);
        for (const [url, article] of Object.entries(prefetchData)) {
          store.set(url, article);
        }
      }
    }
    // TODO: error handling
  }
}