import { PageNumber } from '../actionTypes';

export default (state = 0, action)=>{
  switch (action.type) {
  case PageNumber.inc:
    return ++state;
  case PageNumber.reset:
    return 0;
  default:
    return state;
  }
};