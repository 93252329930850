exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ArticleCard__card__1IlxA {\n  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);\n  margin-top: 16px;\n  padding: 4px;\n  cursor: pointer;\n  margin-left: auto;\n  margin-right: auto;\n  max-width: 400px;\n  border-radius: 4px;\n  background-color: #fefefa;\n}\n\n.ArticleCard__media__2TR9r {\n  width: 100%;\n}\n.ArticleCard__title__1S25X {\n  text-decoration: none;\n  text-align: left;\n  border: 0;\n  padding-left: 4px;\n  padding-right: 4px;\n  flex: 1;\n}\n.ArticleCard__title__1S25X > h3 {\n  font-size: 16px;\n  margin: 0;\n  line-height: 1.25;\n\n}\n\n.ArticleCard__footer__1FeKR {\n  display: flex;\n}\n.ArticleCard__footer__1FeKR > button {\n  margin-right: 2px;\n}\n.ArticleCard__like__3z8uN {\n  \n  color: pink;\n}", ""]);

// exports
exports.locals = {
	"card": "ArticleCard__card__1IlxA",
	"media": "ArticleCard__media__2TR9r",
	"title": "ArticleCard__title__1S25X",
	"footer": "ArticleCard__footer__1FeKR",
	"like": "ArticleCard__like__3z8uN"
};