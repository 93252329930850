import { take } from 'redux-saga/effects';
import store from 'store';

import { ARTICLE as type } from '../actionTypes';

export default function* (){
  while(true){
    const action = yield take(type.fetched);
    try {
      store.set(action.url, action.json);
    } catch (e) {
      store.clearAll();
      store.set(action.url, action.json);
    }
  }
}