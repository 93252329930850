import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import AppBar from './AppBar';
import Footer from './Footer';
import style from './List.css';
import { likelist, article } from '../actions';
import { parse } from '../lib/parse';
import ArticleStyles from './Article.css';

const generateArray = (list) => {
  if(!list.head)return [];
  let array = [];
  let node = {
    key: list.head,
    value: list.list[list.head]
  };
  while(node.value){
    array.push(node);
    if(!node.value.next)break;
    node = {
      key: node.value.next,
      value: list.list[node.value.next]
    };
  }
  return array;
};

const ListStream = ({articles, like}) => {
  const list = generateArray(like).map(({key, value})=>{
    if(!articles[key])return null;
    const textDataList = parse(articles[key].text, ArticleStyles);
    return (
      <div key={key}>
        {[textDataList[0],
          <h1 className={ArticleStyles.title} key='title'>{value.title}</h1>,
          ...textDataList.slice(1),
          <hr key='hr' id={key} />
        ]}
      </div>
    );
  }
  );
  return (
    <React.Fragment>
      <AppBar />
      <main className={ArticleStyles.root}>
        {list}
        <p style={{marginTop: '200px', marginBottom: '400px'}}>「後で読む」に追加した記事を全て読み終えました</p>
      </main>
      <Footer />
    </React.Fragment>
  );
};

ListStream.propTypes = {
  articles: PropTypes.object,
  like: PropTypes.object
};

export const Stream = connect((state, ownProps)=>({
  articles: state.articles,
  like: state.likelist,
  ...ownProps
}), (dispatch)=>({
  read: bindActionCreators(likelist.pop, dispatch)
}))(ListStream);

const Likelist = ({like, read}) => {
  if(process.browser)document.title = '後で読む - GIGAZINE';
  const likeArray = generateArray(like);
  const list = likeArray.map(({key, value}, index)=>(
    <Link 
      to={`/news/${key}`} 
      key={index} 
      onClick={()=>read({url:key})}
    >
      <p>{value.title}</p>
    </Link>
  ));
  // likeArray.forEach(elem=>{
  //   load(elem.key);
  // });
  return (
    <React.Fragment>
      <AppBar />
      <div className={style.root}>
        {list.length !== 0 ? <Link to='/list/stream'>ストリームを開始</Link> : null}
        {list.length !== 0 ? list : <p>「後で読む」に追加した記事を全て読み終えました</p>}
      </div>
      <Footer />
    </React.Fragment>
  );
};

Likelist.propTypes = {
  like: PropTypes.object,
  read: PropTypes.func,
  load: PropTypes.func
};

export const List = connect((state, ownProps)=>({
  like: state.likelist,
  ...ownProps
}), (dispatch)=>({
  read: bindActionCreators(likelist.pop, dispatch),
  load: bindActionCreators(article.request, dispatch)
}))(Likelist);

const listRouter = ()=>(
  <Switch>
    <Route path='/list/stream' component={Stream} />
    <Route path='/list' component={List} />
  </Switch>
);
export default listRouter;