import { HOMESTREAM } from '../actionTypes';

export const request = (page = 0, category = null, {reset = false} = {})=>{
  return {
    type:HOMESTREAM.request,
    page,
    category,
    reset
  };
};

export const request2 = ({page = 0, category = null, reset = false} = {})=>{
  return {
    type:HOMESTREAM.request,
    page,
    category,
    reset
  };
};

export const fetched = (json, {page=0, reset=false} = {})=>{
  return {
    type:HOMESTREAM.fetched,
    json,
    page,
    reset
  };
};

export const error = (err = null)=>{
  return {
    type:HOMESTREAM.error,
    err
  };
};

export const reload = () =>{
  return {
    type: HOMESTREAM.reload
  };
};