import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import rootReducer from '../reducers';
import rootSaga from '../sagas/';

export default (history, defaultState = {
  hogehoge: 'fugafuga'
})=>{
  const sagaMiddleware = createSagaMiddleware();
  const _routerMiddleware = routerMiddleware(history);
  const middlewareChain = [_routerMiddleware, sagaMiddleware];
  const composeEnhancers = process.browser && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  if(process.env.NODE_ENV === 'development'){
    const logger = require('redux-logger').createLogger();
    middlewareChain.push(logger);
    
    const immutableState = require('redux-immutable-state-invariant').default();
    middlewareChain.push(immutableState);
  }

  delete defaultState.likelist;

  const store = createStore(
    connectRouter(history)(rootReducer),
    defaultState,
    composeEnhancers(applyMiddleware(...middlewareChain))
  );
  if(process.browser){
    sagaMiddleware.run(rootSaga);
  }

  return store;
};