import { ARTICLE } from '../actionTypes';

export default(state = {}, action)=>{
  switch(action.type){
  case ARTICLE.fetched:
  case ARTICLE.load:
    return Object.assign({}, state, {
      [action.url]:action.data
    });
  default:
    return state;
  }
};