import { LikeList } from '../actionTypes';

export const push = ({url, title}) => {
  return {
    type: LikeList.add,
    url,
    title
  };
};

export const pop = ({url}) => {
  return {
    type: LikeList.remove,
    url
  };
};

export const read = ({url}) => {
  return {
    type: LikeList.read,
    url
  };
};