import { put, take, call, select } from 'redux-saga/effects';
import fetch from 'isomorphic-fetch';
import store from 'store';

import { article as endpoint } from '../lib/api';
import { ARTICLE as type } from '../actionTypes';
import { article as act } from '../actions';

export default function* (){
  while(true){
    const action = yield take(type.request);
    const articles = yield select(state=>state.articles);

    if(action.force || !articles[action.url]){
      const local = store.get(action.url);
      if(!action.force && local){
        yield put(act.load(action.url, local));
      } else {
        const res = yield call(fetch, endpoint(action.url));
        if(res.status === 200){
          const json = yield call(res=>res.json(), res);
          yield put(act.fetched(action.url, json));
        } else {
          yield put(act.error());
        }
      }
    }
  }
}