import { take, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { HOMESTREAM as type } from '../actionTypes';
import { homestream as act } from '../actions';

export default function* (){
  while(true){
    yield take(type.reload);
    yield put(act.request(undefined, undefined, {reset: true}));
    yield delay(1000*10);
  }
}