exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Home__home__1r5bj {\n  margin-bottom: 72px;\n  padding-bottom: 72px;\n  max-width: 820px;\n  margin-left: auto;\n  margin-right: auto;\n  display: flex;\n  flex-wrap: wrap;\n}", ""]);

// exports
exports.locals = {
	"home": "Home__home__1r5bj"
};