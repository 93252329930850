exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Issues__root__a6XpX {\n  text-align: center;\n  padding-bottom: 75px;\n}\n\n.Issues__root__a6XpX label {\n  margin-top: 25px;\n  margin-bottom: 30px;\n  margin-left: auto;\n  margin-right: auto;\n  display: block;\n  font-size: 1.2rem;\n}\n\n.Issues__root__a6XpX label input {\n  font-size: 1.2rem;\n  border: 1px solid black;\n  max-width: 500px;\n  width: 70%;\n}\n\n.Issues__root__a6XpX label textarea {\n  border: 1px solid black;\n  max-width: 560px;\n  width: 80%;\n  height: 40vh;\n  font-size: 1rem;\n}\n\n.Issues__send__-mz_x {\n  background-color: white;\n  height: 44px;\n  width: 60px;\n  font-size: .8rem;\n}", ""]);

// exports
exports.locals = {
	"root": "Issues__root__a6XpX",
	"send": "Issues__send__-mz_x"
};