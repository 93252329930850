import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AppBar from './AppBar';
import Footer from './Footer';
import { onesignal } from '../actions';

import style from './Setting.css';

export const Setting = ({onesignalState, set}) => {
  const loaded = onesignalState.subscribed !== undefined;
  const disabled = !loaded || onesignalState.subscribed;

  const register = loaded && onesignalState.windowobj.registerForPushNotifications;
  return (
    <React.Fragment>
      <AppBar />
      <div className={style.root}>
        <h2>プッシュ通知</h2>
        <button disabled={disabled} onClick={()=>{
          set('subscribed', true);
          register();
        }}>登録</button>
      </div>
      <Footer />
    </React.Fragment>
  );
};

Setting.propTypes = {
  onesignalState: PropTypes.object,
  set: PropTypes.func
};

export default connect((state, ownProps)=>{
  return {
    onesignalState: state.onesignal,
    ...ownProps
  };
}, dispatch => {
  return {
    set: bindActionCreators(
      onesignal.set,
      dispatch
    )
  };
})(Setting);