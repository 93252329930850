exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Article__root__3f2i8 {\n  word-break: break-all;\n  max-width: 740px;\n  margin: auto;\n  padding-bottom: 85px;\n  padding-left: 20px;\n  padding-right: 20px;\n  font-size: 18px;\n  line-height: 1.58;\n  letter-spacing: -.004em;\n  overflow: hidden;\n}\n\n.Article__root__3f2i8 blockquote {\n  border-left: 4px solid #00582a;\n  margin: 4px 2px 4px 2px;\n  padding: 10px 10px 10px 6px;\n  color: #003f1e;\n  font-size: .9em;\n}\n\n.Article__fit560__3j-dl{\n  width: 100vw;\n  max-width: 560px;\n}\n\n@media (max-width: 570px) {\n  .Article__fit560__3j-dl{\n    transform: translateX(-20px);\n  }\n}\n\n.Article__articleText__9Cn39{\n  max-width: 600px;\n}\n\n.Article__title__1p7VL {\n  font-size: 1.6rem;\n  line-height: 1.25;\n  font-weight: normal;\n  margin-bottom: 8px;\n}\n\n.Article__kanren__24Yvp {\n  max-width: 740px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.Article__kanren__24Yvp > p {\n  padding-left: 6px;\n}", ""]);

// exports
exports.locals = {
	"root": "Article__root__3f2i8",
	"fit560": "Article__fit560__3j-dl",
	"articleText": "Article__articleText__9Cn39",
	"title": "Article__title__1p7VL",
	"kanren": "Article__kanren__24Yvp"
};