import React from 'react';
import { Link } from 'react-router-dom';

export const parse = (__text, classname) => {
  if(process.browser){
    const scriptNode = document.getElementById('pagescripts');
    if(scriptNode)while(scriptNode.firstChild){
      scriptNode.removeChild(scriptNode.firstChild);
    }
  }
  let textOrigin = [...__text];
  let text = [];
  let textBuffer = '';
  let isContinue = new Set();
  while(textOrigin.length>0){
    const line = textOrigin.shift();
    const adImg = /<\/noscript><!-- top_image="([^"]*)"/.exec(line);
    if(adImg)text.push(`<img src="${adImg[1]}" border="0">`);

    if(/<blockquote/.test(line))
      isContinue.add('blockquote');
    if(/<\/blockquote>/.test(line))
      isContinue.delete('blockquote');
    if(line || isContinue.size !== 0){
      textBuffer+=textBuffer?`<br />${line}`:line;
    } else {
      text.push(textBuffer);
      textBuffer = '';
    }
  }
  if(textBuffer)text.push(textBuffer);

  text = text.map(textBuffer=>{
    textBuffer = textBuffer.replace(/<script type='text\/javascript'><!--\/\/<!\[CDATA\[.*<\/p><\/div><p class="preface"><br \/>/,'');
    textBuffer = textBuffer.replace(/<img src="/g, `<img class="${classname.fit560}" src="`);
    textBuffer = textBuffer.replace(/<iframe /g, `<iframe class="${classname.fit560}" `);
    textBuffer = textBuffer.replace(/"http:\/\/i\.gzn\.jp\//g, '"https://i.gzn.jp/');
    textBuffer = textBuffer.replace(/<a href="((?![^"]*\/\/gigazine\.net\/)(?![^"]*\/\/i\.gzn\.jp\/)[^"]*)" (?![^>]*rel=)target="_blank"(?![^>]*rel=)>/g, '<a href="$1" target="_blank" rel="noopener">');
    textBuffer = textBuffer.replace(/rel="nofollow"/g, 'rel="nofollow noopener"');
    textBuffer = textBuffer.replace(/<\/h2><br \/>/g, '</h2>');
    return textBuffer;
  });

  //text:[string]
  let spanList = [];

  for (let index in text){
    let line = text[index];

    //scriptタグはhtmlに挿入
    if(line.search(/<script/)!==-1){
      if(process.browser){
        const first_pos = line.search(/ src="/);
        const end_pos = line.search(/\.js"/);
        const scriptSrc = line.substring(first_pos+6,end_pos+3);
        const script = document.createElement('script');
        script.classList.add('pagescripts');
        script.src = scriptSrc;
        script.async = true;
        const scriptNode = document.getElementById('pagescripts');
        scriptNode.appendChild(script);
      }
    }

    //内部リンクを分解して<Link />に変換
    if (line.search(/:\/\/gigazine.net\/news/)!==-1) {
      const origin_spans = line.split(/(?:<b>|)<a href="http(?:|s):\/\/gigazine.net\/news/);
      const spans = origin_spans.map((span, index)=>{
        if(span.indexOf('/')===0){
          const endurlpos=span.search(/"/);
          let newUrl='/news'+span.substring(0,endurlpos);
          if(newUrl.endsWith('/')){
            newUrl = newUrl.slice(0,-1);
          }
          const textStartPos=span.search(/>/);
          let textEndPos;
          let tagLength = 0;
          if(span.search(/<\/a><\/b>/)!==-1){
            textEndPos=span.search(/<\/a><\/b>/);
            tagLength = 8;
          }else{
            textEndPos=span.search(/<\/a>/);
            tagLength = 4;
          }
          if(textEndPos===-1){
            textEndPos = span.length;
          }
          const innerText=span.substring(textStartPos+1,textEndPos);
          const restText=span.slice(textEndPos+tagLength);
          return (
            <span key={index}>
              <b><Link to={newUrl} dangerouslySetInnerHTML={{__html:innerText}} /></b>
              <span dangerouslySetInnerHTML={{__html:restText}} />
            </span>
          );
        }else{
          return (
            <span dangerouslySetInnerHTML={{__html:span}} key={index} />
          );
        }
      });
      spanList.push(
        <p key={index}>
          {spans}
        </p>
      );
      
    } else { //何もなければそのまま
      spanList.push(
        <p dangerouslySetInnerHTML={{__html: line}} key={index}></p>
      );
      
      
    }

  }
  return spanList;
};