import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { homestream, article } from '../actions';
import AppBar from './AppBar';
import ArticleCard from './ArticleCard';
import Footer from './Footer';

import homeStyles from './Home.css';

const Home = (props) =>{
  if(process.browser)document.title='Gigazine App';
  return (
    <div>
      <AppBar />
      <div className={homeStyles.home}>
        {props.articleCards.map((data,index)=>(
          <ArticleCard data={data}  key={index} />
        ))}
      </div>
      <Footer />
    </div>
  );
};

Home.propTypes = {
  articleCards: PropTypes.array,
  loadArticle: PropTypes.func
};

const mapStateToProps = (state, ownProps)=>{
  return {
    articleCards:state.homestream,
    articleData:state.articles,
    ...ownProps
  };
};
const mapDispatchToProps = (dispatch)=>{
  return {
    loadHomeStream: bindActionCreators(homestream, dispatch),
    loadArticle: bindActionCreators(article.request, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);